<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap mr-1"> {{ $t("Status") }} </span>
          <v-select
            style="width: 160px"
            v-model="selectedStatus"
            label="title"
            :options="statusOptions"
            placeholder="Case Status"
            value="all"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap mr-1"> {{ $t("Extension") }} </span>
          <v-select
            style="width: 160px"
            v-model="selectedExtension"
            label="title"
            :options="extensionOptions"
            placeholder="Extension"
            value="all"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="d-flex align-items-center"></div>
      </b-form-group>
    </div>

    <!-- modal -->
    <b-modal ref="my-modal" hide-footer size="lg" static @shown="updateModal">
      <b-card :title="`${modalName}`" style="height: 60vh; width: 100%">
        <div
          id="stl_cont"
          style="height: calc(100% - 90px)"
          class="w-100 inline-block border"
        >
          <div
            style="
              position: absolute;
              margin-top: 4px;
              margin-left: 8px;
              color: #ffccff;
              font-size: smaller;
            "
          >
            {{ $t("Upper Jaw") }}
          </div>
          <div
            style="
              position: absolute;
              margin-top: 24px;
              margin-left: 8px;
              color: #ccffff;
              font-size: smaller;
            "
          >
            {{ $t("Lower Jaw") }}
          </div>
        </div>
        <b-button
          class="float-right mt-1 mb-1"
          variant="primary"
          @click="downloadModel"
        >
          {{ $t("Download") }}
        </b-button>
      </b-card>
    </b-modal>

    <b-modal
      ref="my-image-modal"
      hide-footer
      size="lg"
      static
      :title="`${modalName}`"
      @shown="updateImageModal"
    >
      <div
        id="image_modal"
        style="height: 50vh; width: 100%"
        class="w-100 inline-block border"
      ></div>
    </b-modal>

    <!-- table -->
    <vue-good-table
      styleClass="vgt-table striped"
      :columns="columns"
      :fixed-header="true"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
        trigger: 'enter',
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <div v-if="props.column.field === 'start_date'" class="px-auto">
          {{ props.row.start_date }}
        </div>

        <div v-else-if="props.column.field === 'end_date'" class="px-auto">
          {{ props.row.end_date }}
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <!-- Column: Invoice Status -->
          <b-avatar
            :id="`case-row-${props.row.id}`"
            size="24"
            :variant="`light-${resolveCaseStatusUI(props.row.status).variant}`"
          >
            <feather-icon
              :icon="`${resolveCaseStatusUI(props.row.status).icon}`"
            />
          </b-avatar>
          <b-tooltip :target="`case-row-${props.row.id}`">
            <p class="mb-0">
              {{ props.row.extension_details }}
            </p>
          </b-tooltip>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="text-nowrap" v-if="props.row.status === 'success'">
            <b-dropdown variant="link" toggle-class="p-0" no-caret right>
              <template #button-content>
                <feather-icon
                  :id="`case-row-${props.row.id}-view-icon`"
                  icon="EyeIcon"
                  size="16"
                  class="text-body align-middle mr-1 cursor-pointer"
                />
              </template>
              <b-dropdown-item @click="showModal(props.row)">
                <span class="align-middle ml-50">
                  {{ $t("Show Results") }}
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="showModal(props.row, true)">
                <span class="align-middle ml-50">
                  {{ $t("Show Intra-Oral Scan") }}
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="showImageModal(props.row)">
                <span class="align-middle ml-50">{{ $t("Show OPG") }} </span>
              </b-dropdown-item>
            </b-dropdown>

            <!-- Dropdown -->
            <b-dropdown variant="link" toggle-class="p-0" no-caret right>
              <template #button-content>
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="text-body align-middle mr-1 cursor-pointer"
                />
              </template>
              <b-dropdown-item
                @click="
                  downloadFiles([
                    props.row.extension_files['upper'],
                    props.row.extension_files['lower'],
                  ])
                "
              >
                <span class="align-middle ml-50"
                  >{{ $t("Download Results") }}
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  downloadFiles([
                    props.row.files['upper'],
                    props.row.files['lower'],
                  ])
                "
              >
                <span class="align-middle ml-50">{{
                  $t("Download Intra Oral Scan")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="downloadFiles(props.row.files['opg'])">
                <span class="align-middle ml-50">{{ $t("Download OPG") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalRecords"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => onPageChange({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTooltip,
  BModal,
  VBModal,
  BAlert,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { StlViewer } from "@/libs/3dviewer/stl_viewer.min.js";
import Ripple from "vue-ripple-directive";
import NProgress from "nprogress";
import CaseService from "@/services/case.service";
import FileService from "@/services/file.service";
import vSelect from "vue-select";
import NotificationService from "../../services/notification.service";

export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BButton,
    BDropdownItem,
    BInputGroup,
    BInputGroupAppend,
    BTooltip,
    BAvatar,
    BCard,
    BModal,
    VBModal,
    BAlert,
    vSelect,
  },
  data() {
    return {
      selectedExtension: { title: "All" },
      extensionOptions: [{ title: "All" }, { title: "3D Reconstruction" }],
      selectedStatus: { title: "All" },
      statusOptions: [
        { title: "All" },
        { title: "Succes" },
        { title: "Processing" },
        { title: "Failed" },
        { title: "Unsupported" },
      ],
      totalRecords: 10,
      modalName: "",
      pageLength: 25,
      page: 1,
      isLoading: false,

      sort: [
        {
          field: "",
          type: "",
        },
      ],
      form: {
        sortOrder: "",
        sortBy: "",
      },
      sortType: "",
      sortField: "",
      rows: [],
      searchTerm: "",
    };
  },

  directives: {
    "b-modal": VBModal,
    Ripple,
  },

  computed: {
    resolveCaseStatusUI() {
      const statusColor = {
        /* eslint-disable key-spacing */
        success: { variant: "success", icon: "CheckCircleIcon" },
        failed: { variant: "danger", icon: "AlertCircleIcon" },
        processing: { variant: "secondary", icon: "ClockIcon" },
        unsupported: { variant: "warning", icon: "XCircleIcon" },
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
    columns() {
      return [
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Status"),
          field: "status",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Extension"),
          field: "extension_name",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Case"),
          field: "name",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Patient"),
          field: "patient_id",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Uploaded"),
          field: "start_date",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Finished"),
          field: "end_date",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },

  created() {
    this.currRow = {};
    this.showOrig = false;
    this.modalName = "Patient 1";

    this.loadItems();
    /*
    window.setTimeout(function()
    {
      var show_item=localStorage.getItem("show_item");
      console.log(show_item);
      this.currRow=JSON.parse(show_item);
      if(typeof(this.currRow)=="object")
      {
        this.currRow=JSON.parse(show_item);
        localStorage.setItem("show_item","");
        console.log(this.currRow);
        this.showModal(this.currRow);
      }
    }.bind(this),222)  */
  },

  methods: {
    formatTime: function (s) {
      return new Intl.DateTimeFormat(
        (navigator.languages || [])[0] ||
          navigator.userLanguage ||
          navigator.language ||
          navigator.browserLanguage ||
          "en",
        {
          hour12: false,
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
      ).format(new Date(s * 1000));
    },

    downloadFiles: function (array) {
      FileService.downloadFiles(array);
    },

    downloadModel: function () {
      this.stl_viewer.download_model(1);
      this.stl_viewer.download_model(2);
    },

    uploadProgress: function (load_status, load_session) {
      this.loadingDone = false;
      console.log(load_status);
      var sumLoading = 0;
      var allToLoad = 0;
      for (var i = 0; i < load_status.length; i++) {
        if (
          typeof load_status[i] === "undefined" ||
          typeof load_status[i]["total"] === "undefined"
        ) {
          continue;
        }
        allToLoad += load_status[i]["total"];
        sumLoading += load_status[i]["loaded"];
      }

      var percentage = sumLoading / allToLoad;
      console.log(percentage);
      if (percentage > 0.9) {
        NProgress.set(0.6);
        setTimeout(this.keepProgressbarBusy.bind(this), 1100);
      } else {
        NProgress.set(percentage * 0.6);
      }
    },

    keepProgressbarBusy: function () {
      if (this.loadingDone == false) {
        NProgress.inc(0.1);
        setTimeout(this.keepProgressbarBusy.bind(this), 300);
      } else {
        NProgress.done();
      }
    },

    uploadDone: function () {
      NProgress.done();
      this.loadingDone = true;
    },

    updateImageModal: function () {
      //this.stl_viewer.add_model({id:1,filename:this.currRow.extension_files[0],color:"#FFDDFF"});
      document.getElementById("image_modal").style.backgroundImage =
        "url(" + this.currRow.files.opg + ")";
      document.getElementById("image_modal").style.backgroundSize = "contain";
      document.getElementById("image_modal").style.backgroundRepeat =
        "no-repeat";
      document.getElementById("image_modal").style.backgroundPosition =
        "center";
    },

    showImageModal: function (row, orig = false) {
      this.modalName =
        "OPG - " +
        row.extension_name +
        " - " +
        row.name +
        " (" +
        row.patient_id +
        ")";
      this.$refs["my-image-modal"].show();

      this.currRow = row;
    },

    hideImageModal() {
      this.$refs["my-image-modal"].hide();
    },

    toggleImageModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-image-modal"].toggle("#toggle-btn");
    },

    showModal: function (row, orig = false) {
      this.showOrig = orig;
      this.currRow = row;

      this.modalName =
        row.extension_name + " - " + row.name + " (" + row.patient_id + ")";
      this.$refs["my-modal"].show();

      if (this.stl_viewer != undefined) {
        this.stl_viewer.clean();
      }
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      console.log(this.page);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      if (params[0].field == "start_date") {
        this.form.sortBy = "createdAt";
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onSearch(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onClear(params) {
      localStorage.setItem("caselist", "[]");
      location.reload();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      let response = await this.$store.dispatch("caseLists/list", {
        page: this.page,
        ...this.form,
        perPage: this.pageLength,
      });
      this.rows = response?.data?.data;
      this.totalRecords = response?.data?.total;
      // CaseService.listCases({
      //     "limit_start": this.pageLength*(this.page-1),
      //     "limit_count": this.pageLength,
      //     "search_string": this.searchTerm,
      //     "sort_type": this.sortType,
      //     "sort_field": this.sortField
      // }).then(function (response)
      // {
      //   this.rows=response.data["rows"];
      //   this.totalRecords=Math.max(response.data["total_records"],this.totalRecords);
      // }.bind(this));
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
